import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { breakpoints } from "../mixins";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  getNewsUrl,
} from "../lib/helpers";
import {
  PortableText,
  SEO,
  Layout,
  HomepageHeader,
  ServiceList,
  ClientList,
  Testimonials,
  Footer,
  FadeInUp,
} from "components";
import styled from "styled-components";
import { DarkBlueCircle, Checkmark } from "assets/svgs";
import VideoPlaceholderImage from "assets/images/video-placeholder.jpg";
import ClassImage from "assets/images/class.jpg";
import TestHouseImage from "assets/images/test-house.jpg";
import FBSocial from "assets/images/fb-social.jpg";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useWindowSize } from "hooks";
import { P, H3, UL, LI } from "../mixins";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityArticle(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data } = props;
  const { windowWidth } = useWindowSize();
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title="Statutory Inspections"
        description={site.description}
        keywords={site.keywords}
        image={FBSocial}
      />
      <HomepageHeader />
      <Container>
        <About>
          <Content>
            <FadeInUp>
              <H3>About Us</H3>
              <P>
                Here at LHS Services, we provide Irelands leading statutory inspections service.
              </P>
              <P>
                Built up over 30 years of experience, our full range of services include the bespoke
                designing of statutory inspections, their implementation and testing to
                certification and repairs.
              </P>
              <P>
                We pride ourselves on being your partners in safety. We are renowned for our
                professionalism and technical innovation in this specialist area.
              </P>
              <P>
                At the heart of our business is the care for our team, your team and your customers’
                safety while at work.
              </P>
              <P>Explore our services below.</P>
            </FadeInUp>
            <FadeInUp>
              <Button to="/about#contactform" stripHash>
                Contact Us
              </Button>
            </FadeInUp>
          </Content>
          <Visual>
            <DarkBlueCircle />
            <FadeInUp>
              <img src={VideoPlaceholderImage} alt="video placeholder" />
            </FadeInUp>
          </Visual>
        </About>
        <Services>
          <SectionTitle>Our Main Services</SectionTitle>
          <ServiceList />
        </Services>
        <ClassService>
          <Content>
            <FadeInUp>
              <H3>C.L.A.S.S. Inspection Programme</H3>
              <P>
                We pride ourselves on being your partners in safety. LHS remains the most
                professional and technically innovative company in our field. We care for our
                employees and client’s safety and continue to provide the most comprehensive
                professional services to some of Irelands largest national and multinational
                companies.
              </P>
              <br />
              <H3>Awards Include</H3>
              <UL>
                <LI>
                  <Checkmark /> Recognition of Excellence from the Irish Maintenance Society.{" "}
                </LI>
                <LI>
                  <Checkmark /> Together in Excellence from the pharmaceutical industry.
                </LI>
                <LI>
                  <Checkmark /> Ministerial Millennium Award for Innovative Health and Safety
                  Projects, from the Health and Safety Authority.
                </LI>
              </UL>
            </FadeInUp>
            <FadeInUp>
              <Button to="/class">Find Out More</Button>
            </FadeInUp>
          </Content>
          <Visual>
            <FadeInUp>
              <img src={ClassImage} alt="video placeholder" />
            </FadeInUp>
          </Visual>
        </ClassService>
        <TestHouse>
          <Content>
            <FadeInUp>
              <H3>Test House</H3>
              <P>
                LHS commercial Test House offers bespoke Product and System Testing. If your product
                needs safety testing, we will create the appropriate test solution and execute it.
                We supply accurate measurements and proven methodology to produce professional /
                legal reports, visual data and video analysis.
              </P>
              <P>
                We also offer the full range of Non-Destructive Testing, Crack Detection, Structural
                Steel Analysis and Mechanical/Metallurgical Testing Service to all sectors of
                industry.
              </P>
            </FadeInUp>
            <FadeInUp>
              <Button to="/services#test-house-and-consultancy">Find Out More</Button>
            </FadeInUp>
          </Content>
          <Visual>
            <FadeInUp>
              <img src={TestHouseImage} alt="video placeholder" />
            </FadeInUp>
          </Visual>
        </TestHouse>
        <PostGrid>
          <SectionTitle>News & Projects</SectionTitle>
          {postNodes.map((node) => (
            <FadeInUp key={node.id}>
              <Post to={getNewsUrl(node.publishedAt, node.slug.current)}>
                {node.mainImage && node.mainImage.asset && (
                  <ImageContainer>
                    <Img fluid={node.mainImage.asset.fluid} alt="banner" />
                  </ImageContainer>
                )}
                {node.title && <Title>{node.title}</Title>}
                {node._rawExcerpt && (
                  <Excerpt>
                    <PortableText blocks={node._rawExcerpt} />
                  </Excerpt>
                )}
              </Post>
            </FadeInUp>
          ))}
          <SectionButton to="/news/">See All News & Projects</SectionButton>
        </PostGrid>
        <Testimonials />
        <Clients>
          <SectionTitle>Our Clients</SectionTitle>
          <ClientList />
        </Clients>
      </Container>
      <Footer />
    </Layout>
  );
};

const SectionTitle = styled(H3)`
  text-align: center;
  margin-top: 0;
  @media (min-width: 768px) {
    grid-column: 1/3;
  }
  @media (min-width: 1150px) {
    grid-column: 1/4;
  }
`;

const Excerpt = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5em;
  color: #232528;
`;

const Title = styled.h3`
  color: #243f93;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 1em;
  margin-bottom: 1.25em;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute !important;
  }
`;

const Post = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 3rem;
  transition: 0.5s;
  text-decoration: none;
  &:after {
    opacity: 0;
    content: "Show More";
    font-weight: 600;
    color: #f94c00;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1rem;
    transition: 0.5s;
  }

  &:hover {
    background: #eaf6ff;
    &:after {
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    padding: 1rem;
    padding-bottom: 3rem;
  }
`;

const PostGrid = styled.section`
  grid-column: 1/7;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
  margin-top: 2.5rem;
  @media (min-width: 768px) {
    grid-column: 2/6;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Button = styled(AnchorLink)`
  background: #f94c00;
  color: white;
  width: auto;
  height: 3rem;
  font-size: 0.875rem;
  padding: 0.5rem 2rem;
  border-radius: 6px;
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
`;

const SectionButton = styled(Button)`
  margin: 2rem auto;
  @media (min-width: 768px) {
    grid-column: 1/3;
  }
  @media (min-width: 1150px) {
    grid-column: 1/4;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: 1/4;
  h3 {
    color: #223f92;
    font-size: 1.25rem;
    line-height: 1.5em;
    margin-top: 0;
  }
  p {
    width: 100%;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5em;
  }
  @media (min-width: 768px) {
    padding: 5rem;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  @media (min-width: 1150px) {
    padding: 0;
    align-items: flex-start;
    max-width: 50%;
    margin: 0;
  }
`;

const Visual = styled.div`
  width: 100%;
  height: auto;
  grid-area: visual;
  position: relative;
  svg {
    position: absolute;
    right: -100%;
    top: -100%;
    width: 150%;
    height: auto;
    z-index: 1;
  }
  img {
    max-width: 100%;
    position: relative;
    z-index: 2;
  }
  @media (min-width: 768px) {
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    svg {
      width: 120%;
      right: -90%;
      top: -60%;
    }
  }
  @media (min-width: 1150px) {
    svg {
      right: -90%;
      top: -50%;
      width: 150%;
    }
  }
`;

const Services = styled.div`
  margin: 2.5rem 0;
  @media (min-width: 700px) {
    margin: 1rem;
  }
`;

const Clients = styled.div`
  grid-column: 1/4;
  @media (min-width: 1150px) {
    grid-column: 3/11;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  @media (min-width: 1150px) {
    flex-direction: row;
    padding: 3rem 1rem;
  }
`;

const About = styled(Article)``;

const ClassService = styled(Article)`
  grid-column: 1/4;
  grid-template-areas: "visual" "content";
  @media (min-width: 1150px) {
    grid-template-areas: "visual content";
  }
`;

const TestHouse = styled(Article)`
  grid-template-areas: "visual" "content";
  @media (min-width: 1150px) {
    grid-template-areas: "content visual";
  }
`;

const Container = styled.section`
  max-width: 100vw;
  margin: 2.5rem auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${Article}:nth-of-type(2) {
    flex-direction: column-reverse;
    @media (min-width: 1150px) {
      flex-direction: row-reverse;
    }
  }
`;

export default IndexPage;
